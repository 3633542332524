body {
  /* overflow-x: hidden; */
}

:global #root {
  width: 100rem;
  margin: 0 auto;
}

img {
  display: block;
}

.wrap {
  /* width: 19.2rem; */
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.top {
  position: relative;
  width: 100%;
  padding-top: 9.583333rem;
  height: 43.541667rem;
  min-width: 75rem;
  background-image: url(https://x0.ifengimg.com/ucms/2023_42/EB6F7A7DADB5F4596F77E8E92E4399353FAC71B7_size646_w3840_h2040.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.topQrcodeImg {
  position: absolute;
  width: 8.854167rem;
  height: 8.854167rem;
  top: 22.65625rem;
  left: 30.3125rem;
}

.video_player {
  position: absolute;
  margin-left: 59.0625rem;
  margin-top: -4.010417rem;
  display: block;
  border: none;
  -o-object-fit: fill;
  object-fit: fill;
  /* outline: none; */
  /* border: 1px solid red; */
  width: 19.583333rem;
  /* height: 744px; */
  border-radius: 1.458333rem;
}

.help {
  width: 74.947917rem;
  margin: 0 auto;
  padding-bottom: 11.822917rem;
  position: relative;
}

.helpImage {
  width: 74.947917rem;
  vertical-align: top;
}

.helpQrcodeImg {
  position: absolute;
  width: 10.416667rem;
  height: 10.416667rem;
  top: 4.739583rem;
  left: 1.458333rem;
}